import { useLazyQuery, useMutation } from '@apollo/client'
import { useQueryClient } from '@tanstack/react-query'
import HOLDINGS_TEMPLATE_URL from 'assets/holdings_template.csv'
import Toast from 'components/Toast'
import type { FormikHelpers } from 'formik'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { getCurrentGroupId } from 'selectors/auth'
import {
  isTypeIdentifierDuplicatedError,
  isTypeIdentifierDuplicatedErrorMessage,
} from 'utils/functions/errors'
import { download } from 'utils/functions/files'
import { getCompanySubjectMutationPayload } from 'utils/gql/helpers/subject-mutation-payload-helper'
import { getCompanySubjectTypeIdentifier } from 'utils/gql/helpers/subjects'
import {
  BULK_IMPORT_SUBJECTS,
  CREATE_SUBJECT_FROM_TYPE,
} from 'utils/gql/mutations/subjects'
import { GET_MINIMAL_SUBJECTS, GET_SUBJECTS } from 'utils/gql/queries/subjects'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { subjectsKeys } from 'utils/queries/subjects'
import { SubjectType } from 'utils/types/subjects'
import { PUBLIC_PERMISSION } from 'utils/gql/helpers/permissions'
import { getDefaultHoldingValues } from '../utils'
import {
  getInitialErrorsFromSuggestionErrors,
  getSuggestionErrors,
  hasServerErrors,
  setRepeatedFundErrors,
  validateServerErrors,
} from '../errors.v2'
import { getSingleSchema } from '../schemas'
import {
  AddHoldingForm,
  AddHoldingFormErrors,
  FundManager,
  HoldingType,
} from '../types'

export interface SingleHoldingModalProps {
  onHideModal: () => void
  parseCSV: (file: File) => Promise<void>
  processingFile: boolean
  fileName: string
  onCreateNewHoldings?: (holdingIds: string[]) => void
  name?: string
  fundManager?: FundManager
}

export const useSingleHoldingModal = ({
  onHideModal,
  onCreateNewHoldings,
  name,
  fundManager,
}: SingleHoldingModalProps) => {
  const intl = useIntl()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const [
    bulkCreateSubjectsFromType,
    { loading: loadingBulkCreateSubjectsFromType },
  ] = useMutation(BULK_IMPORT_SUBJECTS, {
    refetchQueries: [GET_SUBJECTS],
  })

  const [createSubjectFromType, { loading: loadingCreateSubjectFromType }] =
    useMutation(CREATE_SUBJECT_FROM_TYPE, {
      refetchQueries: [GET_SUBJECTS],
    })

  const [fetchDuplicatedSubjects] = useLazyQuery(GET_MINIMAL_SUBJECTS, {
    variables: {
      skip: 0,
      limit: 1,
    },
  })

  const lastValuesSentToServer = useRef<AddHoldingForm>(
    getDefaultHoldingValues()
  )
  const serverErrors = useRef<AddHoldingFormErrors>({
    company: {},
    funds: {
      funds: [],
    },
  })
  const initialName = useRef(name)
  const initialValues = useMemo(
    () => getDefaultHoldingValues(initialName.current, fundManager),
    [fundManager]
  )
  const validationSchema = useMemo(() => getSingleSchema(intl), [intl])

  const queryClient = useQueryClient()

  const downloadTemplate = useCallback(() => {
    download('holdings.csv', HOLDINGS_TEMPLATE_URL)
  }, [])

  const validate = useCallback(
    (values: AddHoldingForm) => {
      const errors = cloneDeep(serverErrors.current)
      validateServerErrors(errors, values, lastValuesSentToServer.current)

      if (values.type === HoldingType.FUND) {
        setRepeatedFundErrors(values.funds!.funds, errors, intl)
      }

      return hasServerErrors(errors, values) ? errors : {}
    },
    [intl]
  )

  const submitCompany = useCallback(
    async (
      values: AddHoldingForm,
      formikHelpers: FormikHelpers<AddHoldingForm>
    ) => {
      lastValuesSentToServer.current = cloneDeep(values)

      try {
        const response = await createSubjectFromType({
          variables: {
            type: 'company',
            data: {
              name: values.company?.name!,
              type: 'company',
              logo: values.company?.logo?.file,
              groupOwned: true,
              attributes: [
                {
                  name: 'website',
                  type: 'string',
                  value: values.company?.website,
                },
                {
                  name: 'pointOfContact',
                  type: 'string',
                  value: values.company?.pointOfContact,
                },
                {
                  name: 'legalEntityName',
                  type: 'string',
                  value: values.company?.legalEntityName,
                },
                {
                  name: 'private',
                  type: 'boolean',
                  value: true,
                },
              ],
              permissions: [
                { entityId: PUBLIC_PERMISSION, read: false, write: false },
                { entityId: currentGroupId, read: true, write: true },
              ],
              options: {
                skipSessionPermissions: true,
              },
            },
          },
        })

        onCreateNewHoldings?.([response.data!.createSubjectFromType.id])

        queryClient.invalidateQueries(
          subjectsKeys.byFilters({
            filters: {
              type: [SubjectType.COMPANY, SubjectType.FUND, SubjectType.DEAL],
            },
          })
        )

        Toast.displayIntl('addHolding.companyCreated', 'success')

        onHideModal()
      } catch (error) {
        if (isTypeIdentifierDuplicatedError(error)) {
          const results = await fetchDuplicatedSubjects({
            variables: {
              query: JSON.stringify({
                typeIdentifier: new URL(values.company?.website!).hostname,
              }),
            },
          })

          const duplicatedCompany = results.data?.subjects?.[0]

          const suggestionErrors = getSuggestionErrors(
            {
              type: 'DuplicatedCompanyWebsiteError',
              company: duplicatedCompany,
            },
            values
          )

          if (suggestionErrors) {
            serverErrors.current =
              getInitialErrorsFromSuggestionErrors(suggestionErrors)
            formikHelpers.setStatus(suggestionErrors)
            formikHelpers.validateForm()
            return
          }

          Toast.displayIntl('addHolding.errors.genericCompany', 'error')
        }
      }
    },
    [
      createSubjectFromType,
      currentGroupId,
      onCreateNewHoldings,
      queryClient,
      onHideModal,
      fetchDuplicatedSubjects,
    ]
  )

  const getFundManagerCompany = useCallback(
    async (
      values: AddHoldingForm,
      formikHelpers: FormikHelpers<AddHoldingForm>
    ): Promise<{
      fundCompanySubjectId: string | null
      isValid: boolean
      isOwnedByGroup?: boolean
    }> => {
      if (!values.funds?.fundManager?.website!)
        return { fundCompanySubjectId: null, isValid: true }

      const results = await fetchDuplicatedSubjects({
        variables: {
          query: JSON.stringify({
            typeIdentifier: getCompanySubjectTypeIdentifier(
              values.funds.fundManager?.website!
            ),
          }),
        },
      })

      const existentCompany = results.data?.subjects?.[0]

      if (!existentCompany)
        return {
          fundCompanySubjectId: null,
          isValid: true,
        }

      if (existentCompany.name === values.funds.fundManager?.name) {
        return {
          fundCompanySubjectId: existentCompany!.id,
          isOwnedByGroup: existentCompany!.groupOwner === currentGroupId,
          isValid: true,
        }
      }

      const suggestionErrors = getSuggestionErrors(
        {
          type: 'DuplicatedFundManagerWebsiteError',
          company: existentCompany,
        },
        values
      )

      serverErrors.current = getInitialErrorsFromSuggestionErrors(
        suggestionErrors!
      )
      formikHelpers.setStatus(suggestionErrors)
      formikHelpers.validateForm()

      return {
        fundCompanySubjectId: null,
        isValid: false,
      }
    },
    [currentGroupId, fetchDuplicatedSubjects]
  )
  const submitFunds = useCallback(
    async (
      values: AddHoldingForm,
      formikHelpers: FormikHelpers<AddHoldingForm>
    ) => {
      const fundNames = values.funds!.funds.filter((fundName) => !!fundName)
      try {
        const { fundCompanySubjectId, isOwnedByGroup, isValid } =
          await getFundManagerCompany(values, formikHelpers)

        if (!isValid) return

        const response = await bulkCreateSubjectsFromType({
          variables: {
            data: [
              {
                names: fundNames,
                type: SubjectType.FUND,
                permissions: [
                  { entityId: currentGroupId, read: true, write: true },
                ],
                parentSubject: fundCompanySubjectId || undefined,
                groupOwned: isOwnedByGroup || false,
                parentSubjectData:
                  !fundCompanySubjectId &&
                  values.funds!.includeFundManager &&
                  values.funds!.fundManager
                    ? getCompanySubjectMutationPayload({
                        name: values.funds!.fundManager!.name,
                        logo: values.funds!.fundManager!.logo?.file,
                        website: values.funds!.fundManager!.website,
                        pointOfContact:
                          values.funds!.fundManager!.pointOfContact,
                        isFundCompany: true,
                        permissions: [
                          { entityId: currentGroupId, read: true, write: true },
                        ],
                        groupOwned: false,
                      })
                    : undefined,
              },
            ],
          },
        })

        const newFunds = response.data!.bulkImportSubjects

        Toast.displayIntl(
          ['addHolding.fundsCreated', { count: newFunds.length }],
          'success'
        )

        onCreateNewHoldings?.(newFunds.map((fund) => fund.id))

        queryClient.invalidateQueries(
          subjectsKeys.byFilters({
            filters: {
              type: [SubjectType.COMPANY, SubjectType.FUND, SubjectType.DEAL],
            },
          })
        )

        onHideModal()
      } catch (error) {
        const gqlError = error.graphQLErrors[0]
        if (
          gqlError.message.some((errMsg) =>
            isTypeIdentifierDuplicatedErrorMessage(errMsg.error)
          )
        ) {
          const duplicatedFunds = gqlError.message.map((errMsg) => errMsg.data)

          const results = await fetchDuplicatedSubjects({
            variables: {
              query: JSON.stringify({
                typeIdentifier: {
                  $in: duplicatedFunds.map((fund) => fund.name),
                },
              }),
              limit: fundNames.length,
            },
          })

          const returnedFunds = results.data?.subjects || []
          const suggestionErrors = getSuggestionErrors(
            {
              type: 'DuplicatedFundNamesError',
              duplicatedFunds,
              returnedFunds,
            },
            values
          )

          if (suggestionErrors) {
            serverErrors.current =
              getInitialErrorsFromSuggestionErrors(suggestionErrors)
          }
          formikHelpers.setStatus(suggestionErrors)
          formikHelpers.validateForm()
        }
      }
    },
    [
      queryClient,
      bulkCreateSubjectsFromType,
      currentGroupId,
      fetchDuplicatedSubjects,
      getFundManagerCompany,
      onCreateNewHoldings,
      onHideModal,
    ]
  )

  const onSubmit = useCallback(
    async (
      values: AddHoldingForm,
      formikHelpers: FormikHelpers<AddHoldingForm>
    ) => {
      lastValuesSentToServer.current = cloneDeep(values)

      if (values.type === HoldingType.COMPANY) {
        submitCompany(values, formikHelpers)
      }

      if (values.type === HoldingType.FUND) {
        submitFunds(values, formikHelpers)
      }
    },
    [submitCompany, submitFunds]
  )

  return {
    intl,
    downloadTemplate,
    initialValues,
    validationSchema,
    validate,
    onSubmit,
    loading: loadingBulkCreateSubjectsFromType || loadingCreateSubjectFromType,
  }
}
