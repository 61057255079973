import { useEffect, useMemo } from 'react'
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll'

import {
  PAGE_SIZE,
  SubjectQueryProps,
  useSubjectsQuery,
} from 'utils/hooks/queries/subjects/useSubjectsQuery'
import { useQuery } from '@tanstack/react-query'
import { portfolioKeys } from 'utils/queries/portfolios'
import PortfolioService from 'api/PortfolioService'
import { PortfolioTypes } from 'utils/constants/portfolio'

export const useSubjectsInfiniteQuery = (filters: SubjectQueryProps) => {
  const {
    ref: endOfPageRef,
    onScreen,
    setInfiniteScrollEnabled,
  } = useInfiniteScroll()

  const query = useSubjectsQuery(filters, {
    onCompleted: (data) => {
      if (data.subjects?.length < (filters?.pageSize || PAGE_SIZE)) {
        setInfiniteScrollEnabled(false)
      }
    },
  })

  useEffect(() => {
    if (onScreen) {
      query.fetchMore()
    }
  }, [onScreen, query])

  return {
    ...query,
    endOfPageRef,
  }
}

export const usePortfoliosHoldingsIds = (
  params: { portfolioType?: PortfolioTypes[]; enabled: boolean } = {
    enabled: true,
  }
) => {
  const query = useQuery(
    portfolioKeys.fundPortfolioHoldings({
      portfolioType: [PortfolioTypes.FUND, PortfolioTypes.DEAL],
    }),
    async () => {
      return PortfolioService.fetchPortfolios({
        portfolioType: params.portfolioType,
        perPage: 9999,
      })
    },
    { enabled: params.enabled }
  )

  return useMemo(() => {
    return query?.data
      ?.map((portfolio) =>
        portfolio.portfolioCompanies.map((company) => company.holding.id)
      )
      .flat()
  }, [query.data])
}
