import { useIntl } from 'react-intl'

import Chip from 'components/Chip'
import Title from 'components/Title'
import Dropdown from 'ui/Dropdown'

import { Industry } from 'utils/types/company'
import * as Styles from '../common/styles'

type CompanyIndustriesProps = {
  industries: Industry[]
  industriesOptions: Industry[]
  onChange: (industries: Industry[]) => void
  isFundCompany: boolean
}
const CompanyIndustries = ({
  industries,
  industriesOptions,
  onChange,
  isFundCompany,
}: CompanyIndustriesProps) => {
  const intl = useIntl()

  const removeIndustry = async (id) => {
    const newIndustries = industries.filter((toolInd) => toolInd.id !== id)

    onChange(newIndustries)
  }

  return (
    <Styles.Section>
      <Title
        title={
          isFundCompany
            ? intl.formatMessage({ id: 'editCompany.fundTargetIndustires' })
            : intl.formatMessage({ id: 'editCompany.addIndustry' })
        }
      />

      <Dropdown
        name="companyIndustry"
        onSelectOption={(_, __, selectedValue) => {
          onChange([
            ...industries,
            {
              id: selectedValue.id,
              name: selectedValue.name,
              sectorId: selectedValue.sectorId,
            },
          ])
        }}
        placeholder={`${intl.formatMessage({
          id: 'editCompany.addIndustryPlaceholder',
        })}...`}
        id="industries-add"
        type="select"
        loadOptions={(searchValue) => {
          return industriesOptions?.filter(
            (industry) =>
              industry.name.toLowerCase().indexOf(searchValue.toLowerCase()) >=
                0 && !industries.some((toolInd) => toolInd.id === industry.id)
          )
        }}
        getOption={(industry) => ({
          ...industry,
          label: industry.name,
        })}
        clearOnSelect
        async
      />

      <Styles.ChipContainer>
        {industries.map(({ name, id }) => (
          <Chip
            key={id}
            text={name}
            canDelete
            handleDelete={() => removeIndustry(id)}
            width="fit-content"
          />
        ))}
      </Styles.ChipContainer>
    </Styles.Section>
  )
}

export default CompanyIndustries
