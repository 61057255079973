import { SubjectMatterType } from 'api/UpdateService'
import { MetricFrequency } from 'utils/constants/metrics'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { MetricSources } from './metrics'
import { SubjectType } from './subjects'

// We need to support Rails subject matter types until Numbers uses only Subjects API and the old metrics are migrated to the new SubjectType
export type MetricSubjectMatterType =
  | Extract<
      SubjectMatterType,
      | SubjectMatterType.COMPANY
      | SubjectMatterType.FUND_PORTFOLIO
      | SubjectMatterType.INVEST_PORTFOLIO
      | SubjectMatterType.TRACK_PORTFOLIO
      | SubjectMatterType.DEAL_PORTFOLIO
    >
  | SubjectType

export interface DataPoint {
  id: string
  value: number
  date: Date
  archived: boolean
  updatedAt: Date
  sharedGroups?: Array<string>
  groupId?: string
}

export interface Milestone {
  id: string
  date: Date
  groupId?: string
  metricId: string
  notifyPercentageReached: boolean
  notifyValueReached: boolean
  percentage: number
  shared: boolean
  timestamp: string
  value: number
}

export interface IndexMetric extends BaseMetric {
  dataPoints: DataPoint[]
}

export interface Metric extends BaseMetric {
  dataPoints: string[]
}

export interface BaseMetric {
  id: string
  name: string
  subject: {
    id: string
    type: MetricSubjectMatterType
    name: string
  }
  source: MetricSources
  milestones: string[]
  updatedAt: Date
  createdAt: Date
  frequency?: MetricFrequency
  receiverMetricLink?: LinkedMetric
  senderLinkedMetric?: Metric
  senderLinks?: LinkedMetric[]
}

export enum LinkedMetricState {
  REQUESTED = 'requested',
  SHARED = 'shared',
  SHARE_ACCEPTED = 'share_accepted',
  SHARE_DENIED = 'share_denied',
  REQUEST_ACCEPTED = 'request_accepted',
  REQUEST_DENIED = 'request_denied',
  UNSHARED = 'unshared',
}

export interface LinkedMetric {
  id: string
  createdAt: Date
  updatedAt: Date
  message: string
  receiveData: boolean
  receiverGroupId?: string
  receiverMetricId?: string
  senderGroupId?: string
  senderMetricId?: string
  state: LinkedMetricState
}

export const getPortfolioTypeBySubjectType = (
  subjectType: MetricSubjectMatterType
) => {
  switch (subjectType) {
    case SubjectMatterType.FUND_PORTFOLIO:
      return PortfolioTypes.FUND
    case SubjectMatterType.INVEST_PORTFOLIO:
      return PortfolioTypes.INVEST
    case SubjectMatterType.TRACK_PORTFOLIO:
      return PortfolioTypes.TRACK
    case SubjectMatterType.DEAL_PORTFOLIO:
      return PortfolioTypes.DEAL
    default:
      return undefined
  }
}

export const getSubjectTypeBySubjectMatterType = (
  subjectMatterType?: SubjectMatterType | SubjectType
): MetricSubjectMatterType | undefined => {
  if (
    subjectMatterType === SubjectMatterType.PORTFOLIO ||
    subjectMatterType === SubjectMatterType.INVESTOR
  ) {
    return undefined
  }

  return subjectMatterType
}
