import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const ScrollWrapper = styled.div`
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Cell = styled.div`
  color: ${color('darkGray')};
  display: flex;
  font-size: 1.4rem;
  justify-content: start;
  overflow-wrap: anywhere;
`
