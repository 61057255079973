/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'

import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { getUser } from 'selectors/auth'
import Toast from 'components/Toast'
import {
  addNewUserGroup,
  setCurrentGroupId,
  setIsSigningUp,
} from 'features/authSlice'

import CompanyService from 'api/CompanyService'
import GroupService from 'api/GroupService'
import { setSignupCompany } from 'actions/companies'
import { getAboutCompanySchema } from 'utils/schemas/aboutCompany'
import { getEmailProviderFromEmailAddress } from 'utils/functions/user'
import { dataUrlToFile } from 'utils/functions/files'
import { ALREADY_TAKEN_ERROR } from 'utils/constants/errors'
import EMAIL_PROVIDERS_LIST from 'utils/constants/emailProviders'
import { SHOW_CLAIM_COMPANY, HIDE_CLAIM_COMPANY } from 'utils/constants/events'
import { CREATE_SUBJECT_FROM_TYPE } from 'utils/gql/mutations/subjects'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { Nullable } from 'utils/types/common'
import { SubjectType } from 'utils/types/subjects'
import { PUBLIC_PERMISSION } from 'utils/gql/helpers/permissions'

interface FounderGroupForm {
  companyName: string
  legalEntityName: string
  website: string
  role: string
  isFounder: boolean
}

export const useFounderGroupSetup = () => {
  const intl = useIntl()
  const [image, setImage] = useState<File>()
  const [imageUrl, setImageUrl] = useState<string>()
  const currentUser = useAppSelector(getUser)
  const history = useHistory()
  const dispatch = useAppDispatch()

  const [showClaimCompany, setShowClaimCompany] = useState(false)
  const [createdGroup, setCreatedGroup] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const [disableButton, setDisableButton] = useState(false)

  const [createSubjectFromType] = useMutation(CREATE_SUBJECT_FROM_TYPE)

  const formik = useFormik<FounderGroupForm>({
    initialValues: {
      companyName: '',
      legalEntityName: '',
      website: 'https://',
      role: '',
      isFounder: false,
    },
    validationSchema: getAboutCompanySchema(intl),

    onSubmit: (values) => handleSubmit(values),
    validateOnBlur: true,
  })

  const handleSubmit = async (values: FounderGroupForm) => {
    const companyDatum = {
      name: values.companyName,
      website: values.website,
      legalEntityName: values.legalEntityName,
    }
    try {
      setDisableButton(true)

      const groupName = values.legalEntityName
      let group
      if (!createdGroup) {
        group = await GroupService.createGroup(groupName, image)
        setCreatedGroup(group)
      } else {
        group = createdGroup
      }

      const response = await createSubjectFromType({
        variables: {
          type: SubjectType.COMPANY,
          data: {
            name: companyDatum.name,
            type: 'company',
            logo: image,
            attributes: [
              {
                name: 'website',
                type: 'string',
                value: companyDatum.website,
              },
              {
                name: 'legalEntityName',
                type: 'string',
                value: companyDatum.legalEntityName,
              },
              {
                name: 'employees',
                type: 'array',
                value: [
                  {
                    email: currentUser.email,
                    firstName: currentUser.firstName,
                    lastName: currentUser.lastName,
                    title: values.role,
                    founder: values.isFounder,
                  },
                ],
              },
            ],
            groupOwned: true,
            permissions: [
              { entityId: PUBLIC_PERMISSION, read: false, write: false },
              { entityId: group.id, read: true, write: true },
            ],
            options: {
              skipSessionPermissions: true,
            },
          },
        },
        context: {
          headers: {
            'x-group-id': group.id,
          },
        },
      })

      const newCompanyData = response.data?.createSubjectFromType!

      await dispatch(
        addNewUserGroup({
          ...group,
          companyData: [{ externalSubjectId: newCompanyData.id }],
        })
      )

      dispatch(setSignupCompany(null))
      dispatch(setIsSigningUp(false))
      dispatch(setCurrentGroupId(group.id))

      history.push('/')
    } catch (error) {
      if (error.data?.errors?.email?.includes(ALREADY_TAKEN_ERROR)) {
        Toast.displayIntl(
          'aboutCompany.createCompanyLegalEntityNameError',
          'error'
        )
      } else if (
        error.data?.errors?.website?.includes(ALREADY_TAKEN_ERROR) ||
        error.data?.errors?.legalEntityName?.includes(ALREADY_TAKEN_ERROR)
      ) {
        const result = await CompanyService.searchPublicProfileByUserId(
          currentUser.id,
          companyDatum.website
        )
        if (result.companyFound) {
          dispatchEvent(SHOW_CLAIM_COMPANY)
          await dispatch(setSignupCompany(result.company))
          setShowClaimCompany(true)
        } else {
          const message = error.data?.errors?.website
            ? 'aboutCompany.createCompanyWebsiteError'
            : 'aboutCompany.createCompanyLegalEntityNameError'

          Toast.displayIntl(message, 'error')
        }
      } else {
        Toast.display(intl.messages['aboutCompany.createCompanyError'], 'error')
      }
    } finally {
      formik.setSubmitting(false)
      setDisableButton(false)
    }
  }

  const collectCompanyInformationByUser = async () => {
    try {
      const emailProvider = getEmailProviderFromEmailAddress(currentUser.email)
      const isCompanyEmail = !EMAIL_PROVIDERS_LIST.includes(emailProvider)

      if (!isCompanyEmail) {
        setIsLoading(false)
        return
      }

      const emailDomain = currentUser?.email.split('@')[1]
      const collectCompanyInfoResponse =
        await CompanyService.crawlCompanyByWebsite(`https://www.${emailDomain}`)

      const company = {
        name: collectCompanyInfoResponse.data?.companyName,
        website: `https://www.${emailDomain}`,
        description: collectCompanyInfoResponse.data?.description,
        linkedinUrl: collectCompanyInfoResponse.data?.linkedinUrl,
        primaryLogo: {
          url: collectCompanyInfoResponse.data?.logoUrl,
        },
        claimCompanyProfile: false,
      }
      await dispatch(setSignupCompany(company))
      formik.setValues({
        companyName: company.name,
        legalEntityName: company.name,
        website: company.website,
        role: formik.values.role,
        isFounder: formik.values.isFounder,
      })
      if (
        company.primaryLogo.url &&
        !company.primaryLogo.url.includes('missing')
      ) {
        const imageFile = await dataUrlToFile(
          company.primaryLogo?.url,
          'companyLogo.jpeg',
          { type: 'image/jpeg' }
        )
        setImage(imageFile)
      }

      setIsLoading(false)
    } catch (error) {
      Toast.display(
        intl.messages['aboutCompany.errorCollectingCompanyInformation'],
        'error'
      )
    }
  }

  useEffect(() => {
    collectCompanyInformationByUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectImage = (imageFile: Nullable<File>) => {
    if (imageFile !== null) {
      setImage(imageFile)
    }
  }

  const onCancelImage = () => {
    setImage(undefined)
    setImageUrl(undefined)
  }

  const onSaveImage = () => {
    if (image) {
      setImageUrl(window.URL.createObjectURL(image))
    }
  }

  const onCancelClaimCompany = () => {
    dispatchEvent(HIDE_CLAIM_COMPANY)
    setShowClaimCompany(false)
  }

  const onConfirmClaimCompany = () => {
    dispatch(setIsSigningUp(false))
    history.push('/welcome')
  }

  return {
    formik,
    intl,
    setImage,
    isLoading,
    imageUrl,
    image,
    onSelectImage,
    onCancelImage,
    disableButton,
    onSaveImage,
    showClaimCompany,
    onCancelClaimCompany,
    onConfirmClaimCompany,
    createdGroup,
  }
}
