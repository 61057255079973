import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Card from 'components/Card'
import InfiniteScroll from 'components/InfiniteScrollRef/InfiniteScroll'
import { SkeletonAllMetricsCardContent } from 'components/Skeletons/Metrics/SkeletonAllMetricsCard/SkeletonAllMetricsCard'
import {
  SkeletonAvatarWithAnimation,
  SkeletonItem,
  SkeletonItemWrapper,
} from 'components/Skeletons/Skeletons.styles'
import BulkImportMetricValuesModal from 'containers/Metrics/BulkImportMetricValuesModal'
import MetricHoldingAvatar from 'containers/Metrics/MetricHoldingAvatar'
import MetricsZeroState from 'containers/Metrics/MetricsZeroState'
import Button from 'ui/Button'
import OptionsDropdown from 'ui/OptionsDropdown'
import { Nullable } from 'utils/types/common'
import { maxSize } from 'utils/constants/breakpoint'
import { METRICS_PAGE_SIZE } from 'utils/constants/metrics'
import { useAutoAnimate } from 'utils/hooks/useAutoAnimate'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import theme from 'utils/theme'
import { getSubjectTypeBySubjectMatterType } from 'utils/types/metricsV2'
import { SubjectMatterType } from 'api/UpdateService'
import { SubjectType } from 'utils/types/subjects'
import ProfileMetric from './components/ProfileMetric/ProfileMetric'
import { useProfileMetrics } from './useProfileMetrics'
import * as Styles from './ProfileMetrics.styles'

interface ProfileMetricsProps {
  companyInfo: {
    id: string
    name: string
    type: SubjectMatterType | SubjectType
    logo: Nullable<string>
  }
  title?: string
  showAvatar?: boolean
  showAddMetricButton?: boolean
  showInvestorMetricZeroState?: boolean
  isRenderedIntoCard?: boolean
  hasClickOnName?: boolean
  requestFounderMetrics?: boolean
  isSandboxable?: boolean
}

const ProfileMetrics: React.FC<ProfileMetricsProps> = ({
  companyInfo,
  title,
  showAvatar,
  showAddMetricButton = true,
  showInvestorMetricZeroState = true,
  isRenderedIntoCard = true,
  hasClickOnName = false,
  requestFounderMetrics = false,
  isSandboxable = false,
}) => {
  const intl = useIntl()
  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const [animationParent] = useAutoAnimate()

  const {
    metrics,
    isLoading,
    onEditMetric,
    onRemoveMetric,
    onAddNewValue,
    showFounderMetricsZeroState,
    fetchNextPage,
    isFetchingNextPage,
    paginationEnabled,
    showNoResults,
    onSetMilestone,
    onEditMilestone,
    onViewMetricDetail,
    exportMetric,
    metricBulkImportModal,
    onImportCsv,
    onHideImportCsvModal,
    onAddMetric,
    onUpdateAllMetrics,
    getAddMetricReadOnlyTooltip,
    getUpdateAllMetricsReadOnlyTooltip,
    metricsRoute,
    searchText,
    onNameClick,
  } = useProfileMetrics({ companyInfo, requestFounderMetrics })

  const showUpdateMetricsButton = !isLoading && !showFounderMetricsZeroState

  const showZeroState = useMemo(
    () => showInvestorMetricZeroState && showFounderMetricsZeroState,
    [showInvestorMetricZeroState, showFounderMetricsZeroState]
  )

  if (!isRenderedIntoCard && showNoResults && showInvestorMetricZeroState) {
    return (
      <Styles.ZeroStateContainer>
        <ZeroState
          type={ZeroStateType.NO_SEARCH_RESULTS}
          maxWidth="50rem"
          intlValues={{ searchText }}
        />
      </Styles.ZeroStateContainer>
    )
  }

  if (showZeroState && !isRenderedIntoCard) {
    return (
      <Styles.ZeroStateContainer>
        <MetricsZeroState />
      </Styles.ZeroStateContainer>
    )
  }

  if (!isRenderedIntoCard && showFounderMetricsZeroState) {
    return null
  }

  const renderContent = () => {
    if (isRenderedIntoCard && isLoading && isFetchingNextPage) {
      return (
        <Styles.LoaderContainer>
          <SkeletonAllMetricsCardContent />
        </Styles.LoaderContainer>
      )
    }

    // Empty state for Founder dashboard
    if (showZeroState || showNoResults) {
      return (
        <Styles.NoMetricsContainer>
          <Styles.NoMetricsText>
            <FormattedMessage id="metrics.noMetrics" />
          </Styles.NoMetricsText>
        </Styles.NoMetricsContainer>
      )
    }

    return (
      <>
        <Styles.Container ref={animationParent}>
          {metrics?.pages?.map((page) =>
            page.data.map((metricData) => {
              return (
                <ProfileMetric
                  key={metricData.id}
                  initialMetricData={metricData}
                  onRemoveMetric={onRemoveMetric}
                  onAddNewValue={onAddNewValue}
                  onEditMetric={onEditMetric}
                  onSetMilestone={onSetMilestone}
                  onEditMilestone={onEditMilestone}
                  onViewMetricDetail={onViewMetricDetail}
                  onImportCsv={() => onImportCsv(metricData)}
                  onExportCsv={exportMetric}
                  linkTo={`${metricsRoute}/${metricData.id}`}
                  isSandboxable={isSandboxable}
                />
              )
            })
          )}
        </Styles.Container>

        {metricBulkImportModal.metric && (
          <BulkImportMetricValuesModal
            show={metricBulkImportModal.show}
            metric={metricBulkImportModal.metric}
            onHide={onHideImportCsvModal}
          />
        )}

        <InfiniteScroll
          enabled={paginationEnabled}
          callback={fetchNextPage}
          pageSize={METRICS_PAGE_SIZE}
        />
      </>
    )
  }

  return (
    <Card
      isExpandable
      padding="0"
      arrowColor={isLoading ? theme.colors.lightGray : undefined}
    >
      <Styles.CardHeader
        padding={isMobile ? '3rem 1.6rem 3rem 2.4rem' : '3rem 2.4rem'}
      >
        {isLoading ? (
          <SkeletonItemWrapper>
            <SkeletonAvatarWithAnimation />
            <SkeletonItem width="12rem" height="1.8rem" />
          </SkeletonItemWrapper>
        ) : (
          <Styles.HeaderContent>
            <Styles.Title
              className="metric-name"
              hasClickOnName={hasClickOnName}
              onClick={() => hasClickOnName && onNameClick()}
            >
              {showAvatar && (
                <MetricHoldingAvatar
                  subjectType={
                    getSubjectTypeBySubjectMatterType(companyInfo?.type)!
                  }
                  subjectId={companyInfo?.id!}
                />
              )}
              <span>{title || <FormattedMessage id="metrics.metrics" />}</span>
            </Styles.Title>
            <Styles.ButtonsContainer>
              {!isMobile ? (
                <>
                  {showUpdateMetricsButton &&
                    getUpdateAllMetricsReadOnlyTooltip(
                      <Styles.UpdateAllButton
                        onClick={() => onUpdateAllMetrics()}
                        link
                        icon={['fal', 'up']}
                      >
                        <FormattedMessage id="metrics.update.updateAll" />
                      </Styles.UpdateAllButton>
                    )}
                  {showAddMetricButton &&
                    getAddMetricReadOnlyTooltip(
                      <Button onClick={onAddMetric} link add>
                        <FormattedMessage id="metrics.addCompanyMetric" />
                      </Button>
                    )}
                </>
              ) : (
                <OptionsDropdown
                  attachToDocument={false}
                  buttonType="square"
                  buttonSize="3.2rem"
                >
                  {showAddMetricButton &&
                    getAddMetricReadOnlyTooltip(
                      <OptionsDropdown.Item
                        label={intl.formatMessage({
                          id: 'metrics.addCompanyMetric',
                        })}
                        icon={['fal', 'plus']}
                        onSelectOption={onAddMetric}
                      />
                    )}
                  {showUpdateMetricsButton &&
                    getUpdateAllMetricsReadOnlyTooltip(
                      <OptionsDropdown.Item
                        label={intl.formatMessage({
                          id: 'metrics.update.updateAll',
                        })}
                        icon={['fal', 'up']}
                        onSelectOption={onUpdateAllMetrics}
                      />
                    )}
                </OptionsDropdown>
              )}
            </Styles.ButtonsContainer>
          </Styles.HeaderContent>
        )}
      </Styles.CardHeader>
      <Card.Body>
        {isLoading ? (
          <Styles.LoaderContainer>
            <SkeletonAllMetricsCardContent />
          </Styles.LoaderContainer>
        ) : (
          renderContent()
        )}
      </Card.Body>
    </Card>
  )
}

export default ProfileMetrics
