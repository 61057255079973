import { DraftValidationResponse } from 'components/AddHoldingModal/types'
import { BulkPlainTypes } from 'utils/functions/bulk'
import convertJsonToFormData from 'utils/functions/convertJsonToFormData'
import ResponseNormalizer from 'utils/functions/responseNormalizer'
import { FundHolding, Holding, isFundHolding } from 'utils/types/company'
import { Tag } from 'utils/types/update'

import { SortDirection } from 'types/graphql-schemas/graphql'
import axiosClient from './httpClient'

export type OrderByProps = 'name' | 'last_update_at' | 'portfolio_type'

export enum HoldingTypeFilter {
  COMPANY = 'company',
  FUND = 'fund',
  DEAL = 'deal',
  ORGANIZATION = 'organization',
}

export interface HoldingsFilters {
  id?: string
  idNotIn?: string[]
  idIn?: string[]
  railsId?: string[]
  name?: string
  holdingName?: string
  orderBy?: OrderByProps
  direction?: SortDirection
  page?: number
  text?: string
  countryId?: string[]
  legalStructureId?: string[]
  legalProvinceId?: string[]
  industryId?: string[]
  sectorId?: string[]
  groupId?: string
  locationPlaceAddress?: string
  tags?: Tag[]
  onlyPortfolio?: boolean
  onlyPrivateCompany?: boolean
  typeIn?: HoldingTypeFilter[]
  portfolioIdNot?: string
}

export interface GetHoldingsProps {
  page: number
  filters: HoldingsFilters
  companiesPerPage: number
}

export enum DraftHoldingType {
  COMPANY = 'Company',
  FUND = 'Fund',
}

export interface DraftCompany {
  index: number
  holding_type: DraftHoldingType
  legal_entity_name: string
  name: string
  website?: string
  point_of_contact?: string
}

export interface DraftFund {
  index: number
  holding_type: DraftHoldingType
  funds: string[]
  include_fund_manager: boolean
  fund_manager_name?: string
  fund_manager_website?: string
  fund_manager_point_of_contact?: string
}

export interface FundPortfolioProfileRequest {
  fund_portfolios: { name: string }[]
  group?: {
    name: string
    email?: string
    website?: string
    logo?: Blob
  }
}

export interface CreateFundPortfolioResponse {
  id: string
  name: string
}

export default class HoldingsService {
  static axiosClient = () => axiosClient(undefined, {}, undefined, true)

  static axiosClientFormData = () => axiosClient(true, {}, undefined, true)

  static getHoldings = async ({
    page,
    filters,
    companiesPerPage,
  }: GetHoldingsProps): Promise<{ holdings: Holding[]; total: number }> => {
    const params: any = {
      page,
      per_page: companiesPerPage,
      'q[id_in]': filters.idIn,
      'q[name_cont]': filters.name,
      'q[id_not_in]': filters.idNotIn,
      'q[text]': filters.text,
      '[sorts]':
        filters?.orderBy && filters.direction
          ? [`${filters.orderBy} ${filters.direction}`]
          : undefined,
      'q[legal_country_id_in]': filters.countryId,
      'q[legal_province_id_in]': filters.legalProvinceId,
      'q[legal_structure_id_in]': filters.legalStructureId,
      'q[belonging_to_industries_included_in]': filters.industryId,
      'q[belonging_to_sectors_include_all]': filters.sectorId,
      'q[group_id_eq]': filters.groupId,
      'q[holding_portfolios_id_eq]': filters.id,
      'q[portfolio_id_not_eq]': filters.portfolioIdNot,
      'q[holding_portfolios_or_fund_portfolios_name_cont]': filters.holdingName,
      'q[locations_place_address_components_long_name_cont]':
        filters.locationPlaceAddress,
      type_in: filters.typeIn,
    }

    if (filters.onlyPortfolio)
      params['q[holdings_in_portfolios]'] = filters.onlyPortfolio

    if (filters.onlyPrivateCompany) {
      params['q[group_id_eq]'] = filters.groupId
      params.type_in = [HoldingTypeFilter.COMPANY]
    }

    if (filters.tags) params.tags_name = filters.tags.map((tag) => tag.name)

    const {
      data: { holdings },
      headers,
    } = await axiosClient().get('/holdings', {
      params,
    })

    return {
      holdings: holdings.map((holdingData) =>
        ResponseNormalizer.normalizeHolding(holdingData.holding)
      ),
      total: Number(headers.total),
    }
  }

  static getHoldingsByPortfolioCompanyId = async (
    portfolioCompanyIds: string[]
  ): Promise<Holding[]> => {
    const params = {
      'q[holding_portfolio_companies_id_in][]': portfolioCompanyIds,
    }

    const {
      data: { holdings },
    } = await this.axiosClient().get('/holdings', {
      params,
    })

    return holdings.map((holdingData) =>
      ResponseNormalizer.normalizeHolding(holdingData.holding)
    )
  }

  static getPortfolioHoldings = async ({
    page,
    filters,
    companiesPerPage,
  }: GetHoldingsProps) => {
    return HoldingsService.getHoldings({
      page,
      filters,
      companiesPerPage,
    })
  }

  static async validateBulkImport(
    holdings: (DraftCompany | DraftFund)[]
  ): Promise<DraftValidationResponse[]> {
    const response = await this.axiosClient().post('/holdings/drafts', {
      object_name: BulkPlainTypes.HOLDING,
      object_instances: holdings,
    })

    return response.data
  }

  static async bulkCreateHoldings(
    holdings: (DraftCompany | DraftFund)[]
  ): Promise<string[]> {
    const formData = convertJsonToFormData({
      object_name: BulkPlainTypes.HOLDING,
      object_instances: holdings,
    })

    const response = await this.axiosClientFormData().post(
      '/holdings/bulk',
      formData
    )
    return response.data.createdHoldingIds
  }

  static createFundPortfolioProfile = async (
    request: FundPortfolioProfileRequest
  ): Promise<CreateFundPortfolioResponse[]> => {
    const url = 'fund_portfolio_profiles'
    const fundProfileFormData = convertJsonToFormData(request)
    const response = await this.axiosClientFormData().post(
      url,
      fundProfileFormData
    )

    return ResponseNormalizer.normalizeFundPortfolioProfile(
      response.data.result,
      response.data.entities
    )
  }

  static getFundHoldingsByName = async (
    name: string
  ): Promise<FundHolding[]> => {
    const {
      data: { holdings },
    } = await this.axiosClient().get('/holdings', {
      params: {
        'q[name_eq]': name,
      },
    })

    return holdings.reduce((acc, holdingData) => {
      const holding = ResponseNormalizer.normalizeHolding(holdingData.holding)

      if (isFundHolding(holding)) {
        acc.push(holding)
      }

      return acc
    }, [])
  }
}
