import { getFundCompanySubjectId } from 'selectors/auth'
import { UpdateTypeForUrl } from 'utils/constants/updates'
import {
  getInitialLinkRedirect,
  getInvestmentNavigationOptionSelected,
  getUrl,
} from 'reducers/breadcrumbSlice'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import useUpdateInformation from './useUpdateInformation'
import useEntityInformation from './useEntityInformation'
import useOrganizationProfileBreadcrumb from './FundManager/hooks/useOrganizationProfileBreadcrumb'

const useBreadcrumbNavigation = () => {
  const url = useAppSelector(getUrl)
  const companyFundId = useAppSelector(getFundCompanySubjectId)
  const investmentOptionSelected = useAppSelector(
    getInvestmentNavigationOptionSelected
  )

  const updateTypeValues = Object.values(UpdateTypeForUrl)

  const isManipulatingUpdate = updateTypeValues.some((value) =>
    url.current.includes(value)
  )

  const isCreatingUpdate = (): boolean => {
    return url.current.includes('new') && isManipulatingUpdate
  }

  const isCreatingNewUpdate = isCreatingUpdate()

  const { fundCompany, getFundCompanyAvatar } =
    useOrganizationProfileBreadcrumb({
      companyFundId: companyFundId ?? '',
    })

  const initialLinkRedirect = useAppSelector(getInitialLinkRedirect)

  const {
    publicFundProfile,
    companyInfo,
    entityToShow,
    isShowingCompany,
    getAvatar,
  } = useEntityInformation()

  const { update } = useUpdateInformation()

  return {
    update,
    entityToShow,
    url,
    initialLinkRedirect,
    isShowingCompany,
    companyInfo,
    publicFundProfile,
    fundCompany,
    isCreatingNewUpdate,
    investmentOptionSelected,
    isManipulatingUpdate,
    getFundCompanyAvatar,
    getAvatar,
  }
}

export default useBreadcrumbNavigation
