import { Group } from 'utils/types/user'
import { SubjectMatterType } from 'api/UpdateService'
import { Image, Nullable, UpdatableEntity } from './common'
import { GooglePlaceData, Location } from './locations'
import { Portfolio, IndexFundPortfolio, PortfolioType } from './portfolios'
import { Tag } from './update'

export interface Country {
  id: string
  name: string
}

export interface Province {
  abbrev: string
  countryId: string
  countryName: string
  createdAt: string
  id: string
  name: string
}

export interface LegalStructure {
  id: string
  name: string
}

interface Subjectable {
  externalSubjectId?: string
}

export interface Company extends UpdatableEntity, Subjectable {
  angellistUrl?: string
  createdAt: Date
  crunchbaseUrl?: string
  description?: string
  doingBusinessAs: string[]
  employeesNumber: number
  founded?: Date
  fund: boolean
  groupId: Nullable<string>
  hidden: boolean
  id: string
  legalEntityName: string
  linkedinUrl?: string
  x?: string
  locations: string[]
  name: string
  primaryLogo: Image
  logoUrl?: string
  qualityAssured: boolean
  updatedAt: Date
  url: string
  verified: boolean
  website?: string
  primaryLocation: GooglePlaceData
  lastUpdateAt?: string
  isPrivateProfile: boolean
  portfolios?: Portfolio[]
  type?: PortfolioType | SubjectMatterType
}

export interface GroupCompany extends Company {
  industries?: Industry[]
}

export interface CompanyData extends Company {
  sectors: Sector[]
  industries: Industry[]
}

export interface CompanyHoldingData extends CompanyHolding {
  sectors: Sector[]
  industries: Industry[]
  employees: Employee[]
  tags: Tag[]
  investmentToolIndustries?: Industry[]
  locationEntities: Location[]
  /**
   * @deprecated Prefer `owner` field when possible since it's being used in most JS files
   */
  group: Group
  owner?: Group
  legalStructure: LegalStructure
}

export interface Industry {
  id: string
  name: string
  sectorId: string
}

export interface Sector {
  id: string
  icon?: any
  name: string
}

export interface PrimaryLocation {
  id: string
}

export interface CompanyIndustry {
  id: string
  createdAt: Date
  updatedAt: Date
}

export interface Employee {
  id: string
  firstName: string
  lastName: string
  founder: boolean
  email?: string
  linkedinUrl?: string
  title?: string
  position: number
}

export interface Socials {
  angellistUrl?: string
  crunchbaseUrl?: string
  linkedinUrl?: string
  twitterUrl?: string
}
export enum HoldingType {
  COMPANY = 'COMPANY',
  FUND = 'FUND',
  DEAL = 'DEAL',
}

export interface HoldingPortfolio {
  id: string
  name: string
  type: PortfolioType
}

interface HoldingEntity extends Subjectable {
  holdingType: HoldingType
  holdingPortfolios?: HoldingPortfolio[]
  holdingFundPortfolios?: HoldingPortfolio[]
  groupHandle?: string
  holdingId: string
  classType?: SubjectMatterType
}

export interface CompanyHolding extends Company, HoldingEntity {
  industries: Industry[]
}
export interface FundHolding extends IndexFundPortfolio, HoldingEntity {
  lastUpdateAt?: string
  fundCompany?: Company
}

export interface DealHolding extends FundHolding, HoldingEntity {
  classType: SubjectMatterType.DEAL_PORTFOLIO
}

export type Holding = (CompanyHolding | FundHolding | DealHolding) & {
  logo?: string
}

export function isCompanyHolding(holding: Holding): holding is CompanyHolding {
  return (
    holding.holdingType ===
      (HoldingType.COMPANY || SubjectMatterType.COMPANY) ||
    holding.type === SubjectMatterType.COMPANY
  )
}

export function isFundHolding(holding: Holding): holding is FundHolding {
  return holding.holdingType === HoldingType.FUND
}

export function isDealHolding(holding: Holding): holding is DealHolding {
  return holding.classType === SubjectMatterType.DEAL_PORTFOLIO
}

export interface FundProfile extends HoldingEntity {
  id: string
  name: string
  fundCompany?: Company
  group?: Group
  hidden: boolean // Private Fund
  type: PortfolioType
  tags: Tag[]
}

type Size = 'small' | 'large'

export const getHoldingImage = (holding: Holding, size?: Size): string => {
  if (isFundHolding(holding) || isDealHolding(holding)) {
    const hasFundManagerInfo = !!holding.group
    const hasFundCompanyInfo = !!holding.fundCompany

    if (!hasFundManagerInfo && !hasFundCompanyInfo) return 'missing.png'

    return (
      holding?.fundCompany?.primaryLogo?.url ??
      holding?.group?.logo?.url ??
      'missing.png'
    )
  }

  switch (size) {
    case 'small':
      return holding?.logo ?? holding?.primaryLogo?.smallLogo?.url

    case 'large':
      return holding?.logo ?? holding?.primaryLogo?.largeLogo?.url

    default:
      return holding?.logo ?? holding?.primaryLogo?.mediumLogo?.url
  }
}

export const getCompanyHoldingUrl = (companyId: string): string => {
  return `/companies/${companyId}/updates`
}

export const getHoldingUrl = (
  holding: Holding,
  urlToShowUpdate?: boolean
): string => {
  const suffix = urlToShowUpdate ? '' : '/updates'

  if (isCompanyHolding(holding)) {
    return `/companies/${holding.id}${suffix}`
  }
  return `/funds/${holding.id}${suffix}`
}

export const isHoldingFromGroup = (
  holding: Holding,
  groupId: string
): boolean => {
  if (isCompanyHolding(holding)) {
    return holding.groupId === groupId
  }
  return holding.group?.id === groupId
}
