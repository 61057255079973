import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import AddToPortfolioButton from 'components/AddToPortfolioButton'
import { useMetricsContext } from 'containers/Metrics/MetricsContext'
import {
  ActionsWrapper,
  addToPortfolioCustomStyle,
  ButtonsWrapper,
  InputContainer,
  PlaceHolderDiv,
  SearchContainer,
} from 'containers/Profiles/CompanyProfile/CompanyProfile.styles'
import { useProfileContext } from 'containers/Profiles/ProfileContext'
import { useSubjectContext } from 'utils/context/subjects/SubjectContext'
import { useUpdatesFiltersContext } from 'containers/UpdatesView/UpdatesFiltersContext'
import { isActingAsFounder, isActingAsInvestorGroup } from 'selectors/auth'
import Input from 'ui/Input'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { SubjectType } from 'utils/types/subjects'
import Tags from '../Tags/Tags.v2'

const HeaderContent = () => {
  const { subject, railsId } = useSubjectContext()

  const intl = useIntl()
  const isFounder = useAppSelector(isActingAsFounder)
  const { onChange, search } = useProfileContext()
  const { onChangeSearch } = useUpdatesFiltersContext()
  const { onChange: onSearchMetrics } = useMetricsContext()
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  const isHolding =
    subject.type === SubjectType.FUND ||
    subject.type === SubjectType.COMPANY ||
    subject.type === SubjectType.DEAL

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeSearch(event)
      onChange(event)
      onSearchMetrics(event.target.value)
    },
    [onChangeSearch, onChange, onSearchMetrics]
  )

  const tagsList = subject.tags
  const holdingName = subject.name
  const isFundHolding = subject.type === SubjectType.FUND

  return (
    <ActionsWrapper>
      {subject ? (
        <ButtonsWrapper>
          {isHolding && !isFounder && !isInvestorGroup && (
            <AddToPortfolioButton
              holdingId={railsId}
              holdingName={holdingName!}
              forceFetch
              btnCustomStyles={addToPortfolioCustomStyle()}
              isFundHolding={isFundHolding}
            />
          )}

          {isHolding && !isInvestorGroup && (
            <Tags tags={tagsList} holdingId={subject.id} />
          )}
        </ButtonsWrapper>
      ) : (
        <PlaceHolderDiv />
      )}
      {!isInvestorGroup && (
        <SearchContainer>
          <InputContainer>
            <Input
              placeholder={intl.formatMessage({
                id: 'general.searchEllipsis',
              })}
              value={search}
              onChange={onInputChange}
              icon={['far', 'search']}
              iconFontSize="1.5rem"
              iconColor="white"
              placeholderColor="white"
            />
          </InputContainer>
        </SearchContainer>
      )}
    </ActionsWrapper>
  )
}

export default HeaderContent
