import type {
  CellTemplate,
  Compatible,
  EventHandlers,
  Uncertain,
  UncertainCompatible,
} from '@silevis/reactgrid'
import {
  getCharFromKeyCode,
  isAlphaNumericKey,
  keyCodes,
} from '@silevis/reactgrid'
import { ReactNode, MutableRefObject } from 'react'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { MetricsSpreadsheetMode } from 'components/UpdateMetricsGrid/MetricsSpreadsheetLogic'
import { CustomCell } from '../types'
import MetricsSpreadsheetDropdown from '../../UpdateMetricsGrid/components/MetricsSpreadsheetDropdown'

export const METRIC_FETCHED_EVENT = 'SPREADSHEET_METRIC_FETCHED_EVENT'
export const UNKNOWN_METRIC_ID = 'unknown'

export interface Metric {
  id: string
  name: string
  fetchingMetric?: boolean
  toCreate?: boolean
  receiverGroups?: { id: string }[]
  companyData?: { id: string }
}

export interface MetricCell extends CustomCell {
  type: 'dropdown'
  metric?: Metric
  initialChar?: string
  initialMetrics: Metric[]
  loading?: boolean
}

export class MetricCellTemplate implements CellTemplate<MetricCell> {
  fetchMetrics: (metricName: string) => Promise<Metric[]>

  fetchMetricsFromCompany: (
    metricName: string,
    companyId: string
  ) => Promise<Metric[]>

  fetchMetricsForHolding: (
    metricName: string,
    rowIndex: number
  ) => Promise<Metric[]>

  eventHandler: MutableRefObject<EventHandlers | undefined>

  mode: MetricsSpreadsheetMode

  companyId?: string

  constructor(
    mode: MetricsSpreadsheetMode,
    fetchMetrics: (metricName: string) => Promise<Metric[]>,
    fetchMetricsFromCompany: (
      metricName: string,
      companyId: string
    ) => Promise<Metric[]>,
    fetchMetricsForHolding: (
      metricName: string,
      rowIndex: number
    ) => Promise<Metric[]>,
    eventHandler: MutableRefObject<EventHandlers | undefined>,
    companyId?: string
  ) {
    this.mode = mode
    this.fetchMetrics = fetchMetrics
    this.fetchMetricsFromCompany = fetchMetricsFromCompany
    this.fetchMetricsForHolding = fetchMetricsForHolding
    this.eventHandler = eventHandler
    this.companyId = companyId
  }

  // eslint-disable-next-line class-methods-use-this
  getCompatibleCell(
    uncertainCell: Uncertain<MetricCell>
  ): Compatible<MetricCell> {
    const { metric } = uncertainCell

    const text = metric?.name || ''
    return {
      ...uncertainCell,
      metric,
      text,
      value: NaN,
      initialMetrics: uncertainCell.initialMetrics || [],
      rowIndex: uncertainCell.rowIndex || 0,
    }
  }

  update(
    cell: Compatible<MetricCell>,
    cellToMerge: UncertainCompatible<MetricCell>
  ): Compatible<MetricCell> {
    let { metric } = cellToMerge
    const { text: metricName } = cellToMerge
    const isPastingFromExcelOrGSheets = !metric && metricName
    const isFilling = metric && metricName && metric.name !== metricName
    const isPastingMetricFromAnotherCompany =
      metric?.companyData?.id && metric?.companyData?.id !== this.companyId

    if (
      isPastingFromExcelOrGSheets ||
      isFilling ||
      isPastingMetricFromAnotherCompany
    ) {
      metric = cell.initialMetrics.find((m) => m.name === metricName)

      if (!metric) {
        /*
         * If the mode is SINGLE_HOLDING, we need can fetch the metric since we know the company
         * otherwise we need to know which company was selected in the other cell, and that information is in applyChangesToGrid
         */
        if (this.mode === MetricsSpreadsheetMode.SINGLE_HOLDING) {
          this.fetchMetrics(metricName).then((metrics) => {
            metric = metrics.find((m) => m.name === metricName)
            dispatchEvent(METRIC_FETCHED_EVENT, { metric, metricName })
          })
        }

        return this.getCompatibleCell({
          ...cell,
          error: undefined,
          metric: {
            id: UNKNOWN_METRIC_ID,
            name: metricName,
            fetchingMetric: true,
          },
        })
      }
    }

    return this.getCompatibleCell({
      ...cell,
      error: undefined,
      metric,
    })
  }

  // eslint-disable-next-line class-methods-use-this
  handleKeyDown(
    cell: Compatible<MetricCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<MetricCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift)
    if (
      !ctrl &&
      !alt &&
      isAlphaNumericKey(keyCode) &&
      !(shift && keyCode === keyCodes.SPACE)
    ) {
      return {
        cell: {
          ...cell,
          initialChar: char,
        },
        enableEditMode: true,
      }
    }
    const isOpen = keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER

    return {
      cell,
      enableEditMode: isOpen,
    }
  }

  render(
    cell: Compatible<MetricCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<MetricCell>, commit: boolean) => void
  ): ReactNode {
    return (
      <MetricsSpreadsheetDropdown
        cell={cell}
        onCellChanged={onCellChanged}
        isInEditMode={isInEditMode}
        loadMetrics={(metricName: string) => {
          return this.fetchMetricsForHolding(metricName, cell.rowIndex)
        }}
        initialMetrics={cell.initialMetrics}
        getCompatibleCell={this.getCompatibleCell}
        eventHandler={this.eventHandler}
      />
    )
  }
}
