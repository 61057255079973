/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SettingsService from 'api/SettingsService'
import CWLoader from 'components/CWLoader'
import Collapsable from 'components/Collapsable'
import Drawer from 'components/Drawer'
import CompanyIndustries from 'components/EditCompany/components/CompanyIndustries'
import CompanyLegalStructure from 'components/EditCompany/components/CompanyLegalStructure'
import CompanyLocation from 'components/EditCompany/components/CompanyLocation'
import CompanyUrls from 'components/EditCompany/components/CompanyUrls'
import TeamMembers from 'components/EditCompany/components/TeamMembers'
import SelectDatePicker from 'components/TimeSelect/SelectDatePicker'
import Title from 'components/Title'
import {
  getCurrentGroupCompaniesIds,
  getCurrentGroupsPrivateCompanies,
  getFundCompanySubjectId,
  isActingAsFounder,
  isActingAsFundManager,
} from 'selectors/auth'
import { getIsFounderVerified } from 'selectors/company'
import ImagePicker from 'ui/ImagePicker'
import Input from 'ui/Input'

import ReadOnlyTooltip from 'components/ReadOnlyTooltip'
import { getInitials } from 'utils/functions/user'
import {
  Country,
  Employee,
  Industry,
  LegalStructure,
  Province,
} from 'utils/types/company'

import ShowInSearch from 'containers/Profiles/components/ShowInSearch'

import {
  getAttribute,
  hasPublicReadPermission,
} from 'utils/gql/helpers/subjects'
import { GooglePlaceData } from 'utils/types/locations'
import CompanyName from 'components/EditCompany/components/CompanyName'
import * as Styles from './CompanyProfileEditDrawer.styles'
import useCompanyProfileDrawer from './useCompanyProfileDrawer'

const CompanyProfileEditDrawer = () => {
  const {
    initialData,
    isLoading,
    setSelectedCompany,
    handleChangeEmployees,
    onAddNewTeamMember,
    toggleHideTeamMember,
    onEditTeamMember,
    onDeleteTeamMember,
    onSaveImage,
    onSelectImage,
    subject,
    setAttribute,
    setMainAttribute,
    onChangeHidden,
    setMainAndCustomAttribute,
    subjectData,
  } = useCompanyProfileDrawer()

  const intl = useIntl()
  const history = useHistory()

  const { companyId: urlSubjectId } = useParams<{ companyId: string }>()

  const isFundCompanyProfile = window.location.href.includes('group-profile')

  const userCompanies = useAppSelector(getCurrentGroupCompaniesIds)
  const userPrivateCompanies = useAppSelector(getCurrentGroupsPrivateCompanies)
  const isUserActingAsFounder = useAppSelector(isActingAsFounder)
  const isUserActingAsFund = useAppSelector(isActingAsFundManager)
  const isFounderVerified = useAppSelector(getIsFounderVerified)

  const isFundCompany = getAttribute<boolean>(subject, 'isFundCompany')?.value
  const fundCompanyId = useAppSelector(getFundCompanySubjectId)

  const groupHasCompany = userCompanies.length
  if (!groupHasCompany) {
    history.push('/settings/group_management/general')
  }

  const dismissCompanyPageMessage = async () => {
    await SettingsService.dismissMessages('companyPage')
  }

  useEffect(() => {
    dismissCompanyPageMessage()
  }, [])

  useEffect(() => {
    if (isFundCompanyProfile) {
      setSelectedCompany(fundCompanyId)
    } else if (isUserActingAsFund && userPrivateCompanies[0]) {
      const id = urlSubjectId || userPrivateCompanies[0]?.id
      setSelectedCompany(id)
    } else if (userCompanies?.[0]) {
      const id = urlSubjectId || userCompanies?.[0]
      setSelectedCompany(id)
    }
  }, [])

  const onCloseDrawerHandler = () => {
    history.goBack()
  }

  const names = [
    subjectData?.name!,
    ...(getAttribute<string[]>(subjectData, 'secondaryNames')?.value ?? []),
  ]

  return (
    <Drawer
      isOpen
      onCloseDrawer={onCloseDrawerHandler}
      headerTitle={intl.formatMessage({ id: 'companyProfile.editCompany' })}
    >
      <Drawer.Content contentPadding="0.6rem 2.3rem">
        {isLoading ? (
          <CWLoader />
        ) : (
          <Styles.Wrapper>
            {(isUserActingAsFund ? isFundCompany : isUserActingAsFounder) && (
              <ShowInSearch
                hidden={
                  subjectData
                    ? !hasPublicReadPermission(subjectData?.permissions)
                    : false
                }
                isFounderVerified={isFounderVerified}
                onChange={(hidden) => onChangeHidden(hidden)}
              />
            )}

            <Collapsable
              titleCustomStyles={Styles.TitleStyles}
              bodyCustomStyles={Styles.Section}
              id="companyProfile.generalInformation"
              initiallyExpanded
            >
              <ImagePicker
                onSaveImage={onSaveImage}
                showRemoveImageButton={false}
                onSelectImage={onSelectImage}
                imageUrl={subjectData?.logo}
                cropperUrl={subjectData?.logo}
                changeImageText={intl.formatMessage({
                  id: 'companyProfile.changeLogo',
                })}
                initials={getInitials(subjectData?.name)}
                helpText={[
                  intl.formatMessage({ id: 'companyProfile.imageFormat' }),
                  intl.formatMessage({
                    id: 'companyProfile.imageFormatLegend',
                  }),
                ]}
                imageSize="4.8rem"
                compressionRate={0.5}
              />

              <CompanyName
                companyNames={names}
                onChange={([mainName, ...secondaryNames]: string[]) => {
                  setMainAndCustomAttribute({
                    mainKey: 'name',
                    mainValue: mainName,
                    customKey: 'secondaryNames',
                    customValue: secondaryNames,
                  })
                }}
                onSetMainName={(newName: string) =>
                  setMainAttribute('name', newName)
                }
              />

              <Title
                title={intl.formatMessage({ id: 'editCompany.companyFounded' })}
              />

              <SelectDatePicker
                id="founded-date"
                name="founded-date"
                initialValue={
                  getAttribute<Date>(subjectData, 'foundedDate')?.value || null
                }
                handleChange={(value) => setAttribute('foundedDate', value)}
                placeholder={intl.formatMessage({
                  id: 'editCompany.foundedPlaceholder',
                })}
              />

              <Title
                title={intl.formatMessage({ id: 'editCompany.website' })}
                subLabel={intl.formatMessage({ id: 'common.requiredField' })}
              />

              {/* <Input
                id="website"
                value={companyInfo?.companyData?.website}
                onBlur={() => !errors.website && onBlur('website')}
                name="website"
                placeholder={intl.formatMessage({
                  id: 'editCompany.companyWebsitePlaceholder',
                })}
                error={errors.website}
                ref={register}
                prefix={
                  <ReadOnlyTooltip
                    place="top"
                    text={intl.formatMessage({
                      id: 'editCompany.unverifiedEditUrl',
                    })}
                    leftOffset={-130}
                    disabled={
                      isUserActingAsFund
                        ? companyInfo?.companyData?.verified
                        : isFounderVerified
                    }
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'link-simple']}
                      className="imageStyle"
                    />
                  </ReadOnlyTooltip>
                }
              /> */}

              <Input
                id="website"
                placeholder={intl.formatMessage({
                  id: 'editCompany.companyWebsitePlaceholder',
                })}
                value={getAttribute<string>(subjectData, 'website')?.value}
                onChange={(event) => {
                  const { value } = event.target
                  setAttribute('website', value)
                }}
                prefix={
                  <ReadOnlyTooltip
                    place="top"
                    text={intl.formatMessage({
                      id: 'editCompany.unverifiedEditUrl',
                    })}
                    leftOffset={-130}
                    disabled={
                      false
                      // isUserActingAsFund
                      //   ? companyInfo?.companyData?.verified
                      //   : isFounderVerified
                    }
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'link-simple']}
                      className="imageStyle"
                    />
                  </ReadOnlyTooltip>
                }
              />

              <Title
                title={intl.formatMessage({
                  id: 'editCompany.description',
                })}
              />
              <Input
                placeholder={intl.formatMessage({
                  id: 'editCompany.writeDescription',
                })}
                id="description"
                value={getAttribute<string>(subjectData, 'description')?.value}
                onChange={(event) => {
                  const { value } = event.target
                  setAttribute('description', value)
                }}
              />

              <CompanyLocation
                locations={
                  getAttribute<GooglePlaceData[]>(subjectData, 'locations')
                    ?.value ?? []
                }
                onChange={(locations: GooglePlaceData[]) => {
                  setAttribute('locations', locations)
                }}
              />
            </Collapsable>

            <Collapsable
              titleCustomStyles={Styles.TitleStyles}
              id="editCompany.links"
            >
              <CompanyUrls
                iconStyle="imageStyle"
                companyData={{
                  linkedinUrl:
                    getAttribute<string>(subjectData, 'linkedinUrl')?.value ??
                    '',
                  crunchbaseUrl:
                    getAttribute<string>(subjectData, 'crunchbaseUrl')?.value ??
                    '',
                  twitterUrl:
                    getAttribute<string>(subjectData, 'twitterUrl')?.value ??
                    '',
                }}
                onEditField={({ key, value }) => setAttribute(key, value)}
              />
            </Collapsable>

            <Collapsable
              titleCustomStyles={Styles.TitleStyles}
              bodyCustomStyles={Styles.Section}
              id="editCompany.team"
            >
              <Title
                title={intl.formatMessage({ id: 'editCompany.companyTeam' })}
              />

              <Input
                id="membersCount"
                name="membersCount"
                placeholder={intl.formatMessage({
                  id: 'editCompany.membersCountPlaceholder',
                })}
                type="number"
                onChange={(event) =>
                  setAttribute('employeesCount', Number(event.target.value))
                }
                value={
                  getAttribute<number>(subjectData, 'employeesCount')?.value
                }
              />

              <TeamMembers
                employees={
                  getAttribute<Employee[]>(subjectData, 'employees')?.value ??
                  []
                }
                handleChangeEmployeeOrder={handleChangeEmployees}
                onAddNewTeamMember={onAddNewTeamMember}
                onEditTeamMember={onEditTeamMember}
                onDeleteTeamMember={onDeleteTeamMember}
                toggleHideTeamMember={toggleHideTeamMember}
              />
            </Collapsable>

            <Collapsable
              titleCustomStyles={Styles.TitleStyles}
              bodyCustomStyles={Styles.Section}
              id="editCompany.otherInformation"
            >
              <CompanyLegalStructure
                data={{
                  legalCountry: getAttribute<Country>(
                    subjectData,
                    'legalCountry'
                  )?.value,
                  legalStructure: getAttribute<LegalStructure>(
                    subjectData,
                    'legalStructure'
                  )?.value,
                  legalProvince: getAttribute<Province>(
                    subjectData,
                    'legalProvince'
                  )?.value,
                  legalEntityName: getAttribute<string>(
                    subjectData,
                    'legalEntityName'
                  )?.value,
                }}
                legalStructures={initialData?.legalStructures ?? []}
                countries={initialData?.countries ?? []}
                provinces={initialData?.provinces ?? []}
                onChange={setAttribute}
              />

              <CompanyIndustries
                industries={
                  getAttribute<Industry[]>(subjectData, 'industries')?.value ??
                  []
                }
                industriesOptions={initialData?.industries ?? []}
                onChange={(industries) =>
                  setAttribute('industries', industries)
                }
                isFundCompany={
                  !!getAttribute<boolean>(subjectData, 'isFundCompany')?.value
                }
              />
            </Collapsable>
          </Styles.Wrapper>
        )}
      </Drawer.Content>
    </Drawer>
  )
}

export default CompanyProfileEditDrawer
