import FundCompanyProfileFunds from 'components/FundCompanyProfileFunds/FundCompanyProfileFunds'
import ProfileMetrics from 'components/ProfileMetrics/ProfileMetrics'
import Tabs from 'components/Tabs'
import ProfileChatWidget from 'containers/Chat/components/ChatWidgets'
import { useIntl } from 'react-intl'
import { GROUP_PROFILE_PREFIX } from 'containers/Profiles/FundManagerProfile/FundManagerProfile'

import { isActingAsFounder, isActingAsInvestorGroup } from 'selectors/auth'
import { getHasChatFeature } from 'selectors/chat'
import { useSubjectContext } from 'utils/context/subjects/SubjectContext'
import { useGroupQuery } from 'utils/hooks/queries/useGroupQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHoldingSubjectData } from 'utils/hooks/subjects/useHoldingSubjectData'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import { buildHoldingTopicEntityFromSubject } from 'utils/types/chatTopicEntity'
import { Redirect, useRouteMatch } from 'react-router-dom'
import { SubjectType } from 'utils/types/subjects'
import { MainContentCardWrapper } from './CompanyMainContent.styles'
import CompanyUpdates from './CompanyUpdates/CompanyUpdates'

const CompanyMainContent = () => {
  const isFounder = useAppSelector(isActingAsFounder)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const hasChatFeature = useAppSelector(getHasChatFeature)
  const planFeatures = usePlanFeatures()
  const intl = useIntl()
  const { path } = useRouteMatch()
  const pathArray = path.split('/')
  const prefix = pathArray[1]
  const isFromGroupProfile = prefix === GROUP_PROFILE_PREFIX
  const { subject, getAttribute, railsId } = useSubjectContext()
  const { isPrivate } = useHoldingSubjectData(subject)

  const isFundCompany = getAttribute<boolean>('isFundCompany')?.value
  const showMetrics =
    planFeatures.areMetricsEnabled &&
    (isFounder || (!isInvestorGroup && !isPrivate))

  const fundGroupId = getAttribute<string>('groupId')?.value
  const { data: groupData } = useGroupQuery(fundGroupId!, {
    enabled: !!fundGroupId,
  })

  const urlPrefix =
    isFromGroupProfile || isFounder ? `/${prefix}` : `/${prefix}/${subject.id}`
  const rootPath =
    isFromGroupProfile || isFounder ? `/${prefix}` : `/${prefix}/:companyId`

  const ROUTES = {
    UPDATES: {
      url: `${urlPrefix}/updates`,
      urlPath: `${rootPath}/updates`,
    },
    INVESTMENTS: {
      url: `${urlPrefix}/investments`,
      urlPath: `${rootPath}/investments`,
    },
    METRICS: {
      url: `${urlPrefix}/metrics`,
      urlPath: `${rootPath}/metrics`,
    },
    CHAT: {
      url: `${urlPrefix}/chat`,
      urlPath: `${rootPath}/chat`,
    },
  }

  const tabItems = [
    {
      id: 'UPDATES',
      title: intl.formatMessage({
        id: 'portfolioDetail.updates',
      }),
      ...ROUTES.UPDATES,
    },
    isFundCompany && {
      id: 'INVESTMENTS',
      title: intl.formatMessage({
        id: 'portfolioDetail.investments',
      }),
      ...ROUTES.INVESTMENTS,
    },
    showMetrics && {
      id: 'METRICS',
      title: intl.formatMessage({
        id: 'metrics.metrics',
      }),
      ...ROUTES.METRICS,
    },
    !isFounder &&
      !isInvestorGroup && {
        id: 'CHAT',
        title: intl.formatMessage({
          id: 'chat.chat',
        }),
        ...ROUTES.CHAT,
      },
  ].filter(Boolean)

  return (
    <MainContentCardWrapper>
      <Tabs tabOffset={24} items={tabItems} withBorder>
        <Tabs.RouteContent path={ROUTES.UPDATES.urlPath}>
          <CompanyUpdates companyId={railsId} />
        </Tabs.RouteContent>

        {isFundCompany && (
          <Tabs.RouteContent path={ROUTES.INVESTMENTS.urlPath}>
            <FundCompanyProfileFunds
              fundCompany={{
                id: subject.id,
                groupId: getAttribute<string>('groupId')?.value!,
                website: getAttribute<string>('website')?.value!,
                group: {
                  name: groupData?.email!,
                  email: groupData?.name!,
                },
              }}
            />
          </Tabs.RouteContent>
        )}

        {showMetrics && (
          <Tabs.RouteContent path={ROUTES.METRICS.urlPath}>
            <ProfileMetrics
              companyInfo={{
                id: railsId,
                name: subject.name,
                type: subject.type as SubjectType,
                logo: subject.logo ?? null,
              }}
            />
          </Tabs.RouteContent>
        )}

        {hasChatFeature && (
          <Tabs.RouteContent path={ROUTES.CHAT.urlPath}>
            <ProfileChatWidget
              topic={
                subject
                  ? buildHoldingTopicEntityFromSubject(subject)
                  : undefined
              }
            />
          </Tabs.RouteContent>
        )}
      </Tabs>
      <Redirect exact from={rootPath} to={ROUTES.UPDATES.url} />
    </MainContentCardWrapper>
  )
}

export default CompanyMainContent
